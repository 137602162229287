<template>
  <div class="login d-flex justify-center align-center">
    <div class="d-flex container-login">
      <div class="temp-login">
        <div class="in-cover">
          <nav class="text-center mt-5">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://asn-unggul.lan.go.id/"
            >
              <img
                src="../assets/img/logo-lan-warna.png"
                height="60px"
                alt="logo"
                class="mr-3"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.tanotofoundation.org/id/"
            >
              <img src="../assets/img/tanoto.png" height="47px" alt="logo" />
            </a>
          </nav>
          <div class="auth-form">
            <div class="temp">
              <section class="mb-3">
                <h1>Selamat Datang!</h1>
              </section>
              <form @submit.prevent="submit">
                <v-text-field
                  v-model="name"
                  counter
                  :error-messages="UsernameError"
                  label="Nama Pengguna"
                  required
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Kata Sandi"
                  :error-messages="PasswordError"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  counter
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                ></v-text-field>
                <div class="button-group mt-5 d-flex flex-column">
                  <v-btn class="mb-3" type="submit" color="#1a237e" dark>
                    <span v-if="loading" class="d-flex">
                      <Loading propsColor="white" /> Silakan tunggu ...
                    </span>
                    <span v-else> MASUK </span>
                  </v-btn>
                  <small class="forgot-pwd" @click="dialog = true"
                    >Lupa kata sandi?</small
                  >
                  <ForgotPassword
                    v-bind:dialog="dialog"
                    v-on:false="dialogFalse"
                  />
                </div>
              </form>
              <!-- snackbar notification -->
              <v-snackbar v-model="snack" color="red" top>
                {{ errMsg }}
                <template v-slot:action="{ attrs }">
                  <v-btn color="white" text v-bind="attrs" @click="closeSnack">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
          </div>
        </div>
      </div>
      <div class="cover-auth">
        <div class="crousel-temp pa-2">
          <v-carousel
            v-model="model"
            hide-delimiters
            cycle
            interval="3000"
            height="300"
          >
            <v-carousel-item v-for="(item, idx) in slides" :key="idx">
              <v-sheet :color="item.color" height="100%" tile>
                <v-row class="fill-height" align="center" justify="center">
                  <div class="d-flex flex-column align-center">
                    <div class="carousel-card">
                      <img :src="item.img" height="100px" alt="" />
                    </div>
                    <h4 class="text-center pa-2">{{ item.tag }}</h4>
                  </div>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Loading from "../components/_base/loader";
import ForgotPassword from "./sendLink";
export default {
  components: {
    Loading,
    ForgotPassword
  },
  mixins: [validationMixin],
  validations: {
    name: { required },
    password: { required, minLength: minLength(8) }
  },
  computed: {
    ...mapState(["errMsg", "snack", "loading", "precentage"]), //get State from vuex(Store)
    UsernameError() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Nama Pengguna harus di isi!");
      return errors;
    },
    PasswordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Kata sandi minimal 8 karakter!");
      !this.$v.password.required && errors.push("Kata sandi harus di isi!");
      return errors;
    }
  },
  watch: {
    snack(val) {
      console.log(val);
    },
    loading(val) {
      console.log(val);
    }
    // precentage(val){
    //   console.log(val);
    // }
  },
  data: () => ({
    dialog: false,
    name: "",
    password: "",
    snackbar: false,
    show: false,
    model: 0,
    slides: [
      {
        img: "https://dashboard.anargya.id/img/IconGamifikasi1.png",
        tag: "Strategi melatih kerjasama tim",
        color: "#1a237e"
      },
      {
        img: "https://dashboard.anargya.id/img/IconGamifikasi2.png",
        tag: "Melatih untuk berpikir kritis",
        color: "#1a237e"
      },
      {
        img: "https://dashboard.anargya.id/img/IconGamifikasi3.png",
        tag: "Mampu menumbuhkan inovasi baru",
        color: "#1a237e"
      },
      {
        img: "https://dashboard.anargya.id/img/IconGamifikasi4.png",
        tag: "Memotivasi proses pembelajaran",
        color: "#1a237e"
      },
      {
        img: "https://dashboard.anargya.id/img/IconGamifikasi5.png",
        tag: "Mengembangkan perilaku positif terhadap metode pembelajaran",
        color: "#1a237e"
      },
      {
        img: "https://dashboard.anargya.id/img/IconGamifikasi6.png",
        tag:
          "Memberi kesempatan untuk berkompetisi, bereksplorasi dan berprestasi dalam kelas",
        color: "#1a237e"
      },
      {
        img: "https://dashboard.anargya.id/img/IconGamifikasi3.png",
        tag: "Meningkatkan ketertarikan penggunanya",
        color: "#1a237e"
      },
      {
        img: "https://dashboard.anargya.id/img/IconGamifikasi5.png",
        tag: "Menambah kesenangan saat belajar",
        color: "#1a237e"
      },
      {
        img: "https://dashboard.anargya.id/img/IconGamifikasi6.png",
        tag: "Tidak menggambarkan kompetensi yang sedang diukur",
        color: "#1a237e"
      },
      {
        img: "https://dashboard.anargya.id/img/IconGamifikasi4.png",
        tag: "Peserta tidak mengetahui apa yang sedang diukur",
        color: "#1a237e"
      }
    ]
  }),
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        this.$store
          .dispatch("login", { name: this.name, password: this.password })
          .then(res => {
            console.log(res);
            this.randomColor();
            this.$router.go("/");
          })
          .catch(error => {
            this.loading = false;
            return error;
          });
      }
    },
    randomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      localStorage.setItem("xYzvQ", color);
    },
    dialogFalse() {
      this.dialog = false;
    },
    closeSnack() {
      this.$store.commit("SNACKY", false);
    }
  }
};
</script>

<style>
.login {
  height: 100vh;
  background: #e3e7ed;
}
.btn-primary {
  background: #1a237e;
}
.container-login {
  height: 70vh;
  width: 60%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 18px 5px rgba(0, 0, 0, 0.17);
}
.temp-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #1a237e;
  border-radius: 20px 0 0 20px;
  width: 50%;
}
.cover-auth {
  background: #1a237e;
  box-sizing: border-box;
  display: flex;
  width: 50%;
}
.crousel-temp {
  display: flex;
  align-items: center;
  width: 100%;
}
.carousel-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  height: 150px;
  width: 250px;
  border-radius: 8px;
}
.overflow-img {
  z-index: 2;
  position: relative;
  top: -50px;
  left: -10px;
}
.overflow-img img {
  transform: rotate(20deg);
  position: absolute;
}
.right {
  display: none;
}
.in-cover {
  width: 100%;
  background: #fff;
  height: 100%;
  border-radius: 15px;
  z-index: 3;
  box-shadow: 17px 0px 27px 0px rgba(0, 0, 0, 0.25);
}
.auth-form {
  display: flex;
  justify-content: center;
}
.temp {
  background: #fff;
  width: 90%;
  height: 300px;
  padding: 20px;
  border-radius: 5px;
}
.forgot-pwd {
  cursor: pointer;
}
.forgot-pwd:hover {
  color: #1a237e;
}
@media (max-width: 700px) {
  .right {
    display: block;
  }
  .cover-auth {
    display: none;
  }
  .temp-login {
    width: 100%;
    background: none;
  }
  .container-login {
    width: 90%;
  }
}
</style>
