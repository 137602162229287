<template>
  <v-row justify="start">
    <v-dialog v-model="dialog" persistent max-width="300px">
      <v-card v-if="send == 0">
        <v-toolbar color="indigo">
          <span class="headline">
            <v-icon dark> mdi-email-sync-outline </v-icon>
            <span class="white--text"> Lupa Kata sandi </span>
          </span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="email"
                  v-model="email"
                  :error-messages="errMsg"
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                  hint="example@yourmail.com"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('false')">
            Kembali
          </v-btn>
          <v-btn color="indigo" dark v-if="loading"> Loading ... </v-btn>
          <v-btn color="indigo" dark @click="sendEmail" v-else>
            kirim email
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-toolbar color="indigo">
          <span class="headline">
            <v-icon dark> mdi-email-sync-outline </v-icon>
            <span class="white--text"> Lupa Kata sandi </span>
          </span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="3"
                ><v-icon>mdi-email-check-outline</v-icon></v-col
              >
              <v-col cols="12" md="9"> Email sudah terkirim</v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3"></v-col>
              <v-col cols="12" md="9"> Silakan cek email Anda</v-col>
            </v-row>
            <v-row>
              <v-btn color="blue darken-1" text @click="close">
                Kembali
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "forgotPassword",
  props: ["dialog"],
  mixins: [validationMixin],
  validations: {
    email: { required }
  },
  computed: {
    errMsg() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("email harus di isi!");
      return errors;
    }
  },
  data() {
    return {
      email: "",
      send: 0,
      loading: false
    };
  },
  methods: {
    sendEmail() {
      this.$v.email.$touch();
      if (!this.$v.email.required) {
        return;
      } else {
        this.loading = true;
        this.$store.dispatch("sendLink", { email: this.email }).then(() => {
          this.loading = false;
          this.send = 1;
        });
      }
    },
    close() {
      this.$emit("false");
      this.send = 0;
    }
  }
};
</script>

<style></style>
